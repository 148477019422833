/** @format */

import { Routes, Route, Navigate } from "react-router-dom";
import React, { Suspense } from "react";
const Login = React.lazy(() => import("./components/Login"));
const Home = React.lazy(() => import("./view/dashboards/Home"));
const Datasets = React.lazy(() => import("./Datasets/Datasets"));
const AddDatasets = React.lazy(() => import("./Datasets/AddDatasets"));
const Charts = React.lazy(() => import("./view/echarts/Charts"));
const DatabaseList = React.lazy(() => import("./view/database/DatabaseList"));
const AddCharts = React.lazy(() => import("./view/echarts/AddCharts"));
const Canvas = React.lazy(() => import("./view/canvas/Canvas"));
const AddDashBoard = React.lazy(
	() => import("./view/dashboards/AddDashBoards"),
);

const Permission = React.lazy(
	() => import("./view/permissionManagement/permission"),
);
const AddPermission = React.lazy(
	() => import("./view/permissionManagement/addPermission"),
);
const PermissionList = React.lazy(
	() => import("./view/permissionManagement/permissionList"),
);
const EditRolePermission = React.lazy(
	() => import("./view/permissionManagement/editRolePermission"),
);
const Cockpit = React.lazy(() => import("./view/dashboards/Cockpit"));
const LargeDataScreen = React.lazy(() => import("./view/dashboards/LargeDataScreen"));
const CashAnalysis = React.lazy(() => import("./view/dashboards/CashAnalysis"));
const CostAnalysis = React.lazy(() => import("./view/dashboards/CostAnalysis"));
const InvoicingDaily = React.lazy(() => import("./view/dashboards/InvoicingDaily"));
const OverviewPerspective = React.lazy(() => import("./view/dashboards/OverviewPerspective.js"));
const FinancialStatement = React.lazy(() => import("./view/dashboards/FinancialStatement"));
const Main = () => {
	return (
		<div style={{ flexGrow: "1", overflow: "auto" }}>
			<Suspense fallback={""}>
				<Routes>
					<Route path='' element={<Navigate to='login' />} />
					<Route path='login' element={<Login />} />
					<Route path='home' element={<Navigate to='dashboards' />} />

					<Route path='home/dashboards' element={<Cockpit />} />
					<Route path='home/LargeDataScreen' element={<LargeDataScreen />}/>
					<Route path='home/CashAnalysis' element={<CashAnalysis />}/>
					<Route path='home/CostAnalysis' element={<CostAnalysis />}/>
					<Route path='home/InvoicingDaily' element={<InvoicingDaily />}/>
					<Route path='home/OverviewPerspective' element={<OverviewPerspective />}/>
					<Route path='home/FinancialStatement' element={<FinancialStatement />}/>
					<Route path='admin' element={<Navigate to='list' />} />
					<Route path='admin/list' element={<Home />} />

					<Route path='home/add/:id' element={<AddDashBoard />} />
					<Route path='datasets' element={<Navigate to='list' />}></Route>
					<Route path='datasets/list' element={<Datasets />} />
					<Route path='datasets/add/:id' element={<AddDatasets />} />
					<Route path='database' element={<Navigate to='list' />} />
					<Route path='database/list' element={<DatabaseList />} />
					<Route path='charts' element={<Navigate to='list' />}></Route>
					<Route path='charts/list' element={<Charts />} />
					<Route path='charts/add' element={<AddCharts />} />
					<Route path='canvas' element={<Canvas />} />
					<Route path='permission' element={<Navigate to='list' />}></Route>
					<Route path='permission/list' element={<Permission />} />
					<Route path='permission/add' element={<AddPermission />} />
					<Route path='permission/views' element={<PermissionList />} />
					<Route path='permission/edit/:id' element={<EditRolePermission />} />
				</Routes>
			</Suspense>
		</div>
	);
};
export default Main;
